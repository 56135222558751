body{
    background:#f9f9f9;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #fd5c4c;
    border-color: #fd5c4c;
}
.d-btn{
    background: #fd5c4c;
    border-color: #fd5c4c;
}
.d-btn:hover, .d-btn:focus{
    background:#ec4737;
    border-color: #fd5c4c;
}


.d-link, .d-link:focus, .d-link:hover{
    color:#ec4737;
}
.d-link{
    color:#fd5c4c;
    border-bottom-color: #ec4737;
}